import { getDefaultConfig } from '@rainbow-me/rainbowkit';
import { bsc } from 'wagmi/chains';
import { http } from 'wagmi';

export const config = getDefaultConfig({
  appName: 'BGSC Dex Staking',
  projectId: '5656c8460419c36ce591b4d738398791',
  chains: [
    bsc,
  ],
  transports: {
    [bsc.id]: http(),
  },
  ssr: true
});
