import './styles.css';
import { RainbowKitProvider, ConnectButton, useConnectModal } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { WagmiProvider, useAccount, useDisconnect } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { config } from './wagmi';
import React, { useState, useEffect } from 'react';

// 로켓 애니메이션 컴포넌트
const RocketAnimation = () => {
  const [showRocket, setShowRocket] = useState(false);
  
  useEffect(() => {
    // 페이지 로드 후 약간의 지연 시간을 두고 로켓 표시
    const timer = setTimeout(() => {
      setShowRocket(true);
    }, 1500);
    
    // 애니메이션이 끝난 후 로켓 숨기기 (애니메이션 시간 + 약간의 여유)
    const hideTimer = setTimeout(() => {
      setShowRocket(false);
    }, 6300);
    
    return () => {
      clearTimeout(timer);
      clearTimeout(hideTimer);
    };
  }, []);
  
  if (!showRocket) return null;
  
  return (
    <div className="rocket-wrapper">
      <img 
        src={require('./assets/how_icon04.png')} 
        alt="Rocket" 
        className="rocket-animation" 
      />
      <div className="rocket-trail"></div>
    </div>
  );
};

// QueryClient 인스턴스 생성 - 애플리케이션 전체에서 사용
const queryClient = new QueryClient();

// 법적 검토 알림 모달 컴포넌트
const LegalReviewModal = ({ language, onClose, onConfirm }: { 
  language: string, 
  onClose: () => void,
  onConfirm?: () => void
}) => {
  return (
    <div className="modal__wr" style={{ 
      zIndex: 100000, 
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0, 0, 0, 0.5)'
    }}>
      <div className="modal__box">
        <div className="modal__tit">
          <p>{language === 'ko' ? '법적 검토 안내' : 'Legal Review Notice'}</p>
          <button className="modal__close" onClick={onClose}>×</button>
        </div>
        <div className="modal__cont">
          <p style={{ textAlign: 'center', padding: '15px 0', fontSize: '16px', lineHeight: '1.6', color: '#dedede' }}>
            {language === 'ko' 
              ? '지갑 연결은 제공되지만, 실제 사용은 현재 법적 검토중입니다.' 
              : 'Wallet connection feature is currently under legal review. Service will be available after review.'}
          </p>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <button className="confirm-btn" onClick={() => {
              if (onConfirm) onConfirm();
              onClose();
            }}>
              {language === 'ko' ? '확인' : 'Confirm'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// 헤더 컴포넌트
const Header = ({ language, toggleLanguage, openLegalReviewModal }: { 
  language: string, 
  toggleLanguage: () => void,
  openLegalReviewModal: (onConfirm?: () => void) => void 
}) => {
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState('');
  const { openConnectModal } = useConnectModal();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  // 지갑 연결 상태 변경 감지
  useEffect(() => {
    if (address) {
      // 지갑이 연결되면 주소 형식 변경 및 상태 업데이트
      const shortAddress = `${address.substring(0, 5)}...${address.substring(address.length - 3)}`;
      setWalletAddress(shortAddress);
      setIsWalletConnected(true);
    } else {
      setIsWalletConnected(false);
      setWalletAddress('');
    }
  }, [address]);

  // 지갑 연결 버튼 클릭 핸들러
  const handleConnectClick = (e: React.MouseEvent) => {
    e.preventDefault();
    // 지갑 선택 모달을 열고
    if (openConnectModal) {
      openConnectModal();
    }
    // 법적 고지 모달도 동시에 표시 (위에 뜨도록)
    openLegalReviewModal();
  };

  // 지갑 연결 해제 핸들러
  const handleDisconnectClick = (e: React.MouseEvent) => {
    e.preventDefault();
    disconnect();
  };

  return (
    <header>
      <div className="logo">
        <img src={require('./assets/main_logo.svg').default} alt={language === 'ko' ? "벅스코인 로고" : "BGSC Logo"} id="logo" />
      </div>
      {!isWalletConnected ? (
        <a href="#" className="main__btn" onClick={handleConnectClick}>
          Connect
        </a>
      ) : (
        <div className="wallet-connected">
          <span className="address">{walletAddress}</span>
          <a href="#" className="disconnect-btn" onClick={handleDisconnectClick}>
            Disconnect
          </a>
        </div>
      )}
    </header>
  );
};

// 메인 콘텐츠 컴포넌트
const MainContents = ({ language, openLegalReviewModal }: { 
  language: string,
  openLegalReviewModal: (onConfirm?: () => void) => void 
}) => {
  const { openConnectModal } = useConnectModal();

  // 지갑 연결 버튼 클릭 핸들러
  const handleConnectClick = () => {
    if (openConnectModal) {
      // 지갑 선택 모달 열기
      openConnectModal();
      // 법적 고지 모달도 동시에 표시 (위에 뜨도록)
      openLegalReviewModal();
    }
  };

  return (
    <div className="main__contents__wr00" id="main__contents__wr00">
      <div className="inner__box">
        <div className="main__title__wr">
          <p className="tit">{language === 'ko' ? '스테이킹 생태계 법적 검토중' : 'Staking Ecosystem Legal Review in Progress'}</p>
          <p className="title">BGSC Dex <br/>Staking Earn</p>
          <p className="text">
            {language === 'ko' 
              ? <>레퍼럴 기반 수익모델과 실질적인 영업이익을 바탕으로<br/>지속 가능한 스테이킹 생태계를 구축합니다.</>
              : <>We build a sustainable staking system based on<br/>referral-based revenue model and real profits.</>}
          </p>
        </div>
        <div className="img__wr"><img src={require('./assets/main_section_logo01.gif')} alt="" /></div>
        <button 
          onClick={handleConnectClick} 
          className="main__btn contents__wr00__btn"
        >
          {language === 'ko' ? 'Connect Wallet' : 'Connect Wallet'}
        </button>
      </div>
    </div>
  );
};

// 핵심 포인트 컴포넌트
const KeyPoints = ({ language }: { language: string }) => {
  return (
    <div className="main__contents__wr main__contents__wr01" id="main__contents__wr01">
      <div className="inner__box">
        <a href="#" className="main__btn contents__wr00__btn">
          {language === 'ko' ? 'POINT' : 'POINT'}
        </a>
        <div className="main__title__wr">
          <p className="title">{language === 'ko' ? '핵심 포인트' : 'Key Points'}</p>
        </div>

        <div className="main__contents__box main__contents__box01" id="main__contents__box01">
          <ul className="text__list">
            <li>
              <div className="main__text__wr">
                <p className="tit">Point 1</p>
                <p className="title">{language === 'ko' ? 'USDT 보상 선지급' : 'USDT Reward Prepayment'}</p>
                <p className="text">
                  {language === 'ko' 
                    ? <>신청 마감 후, 보상을 USDT로 미리 지급합니다.</>
                    : <>After closing applications, rewards are paid in advance in USDT.</>}
                </p>
              </div>
            </li>
            <li>
              <div className="main__text__wr">
                <p className="tit">Point 2</p>
                <p className="title">{language === 'ko' ? 'USDT 지급으로 인플레이션 없음' : 'No Inflation with USDT Payment'}</p>
                <p className="text">
                  {language === 'ko' 
                    ? <>보상은 달러에 연동된 USDT로 지급되어, 화폐 가치 하락이나 물가 상승 걱정이 없습니다.</>
                    : <>Rewards are paid in USDT, which is pegged to the dollar,so there is no worry about currency devaluation or inflation.</>}
                </p>
              </div>
            </li>
            <li>
              <div className="main__text__wr">
                <p className="tit">Point 3</p>
                <p className="title">{language === 'ko' ? '규모 고정 → 인플레이션 없음' : 'Fixed Reward Scale → No Inflation'}</p>
                <p className="text">
                  {language === 'ko' 
                    ? <>보상 총액이 미리 정해져 있어, 공급량 증가로 인한 가치 하락이 발생하지 않습니다.</>
                    : <>The total reward amount is predetermined, so there is no value decrease due to increased supply.</>}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

// 서비스 이용 방법 컴포넌트
const HowToUse = ({ language }: { language: string }) => {
  return (
    <div className="main__contents__wr main__contents__wr02" id="main__contents__wr02">
      <div className="inner__box">
        <div className="logo_wr"><img src={require('./assets/how_title_logo.png')} alt="" /></div>
        <div className="main__title__wr">
          <p className="title">{language === 'ko' ? '서비스 이용 방법' : 'How to Use the Service'}</p>
        </div>

        <div className="main__contents__box main__contents__box02" id="main__contents__box02">
          <ul className="text__list">
            <li>
              <div className="main__text__wr">
                <p className="tit">CONNECT WALLET</p>
                <div className="img__wr"><img src={require('./assets/how_icon01.png')} alt={language === 'ko' ? "지갑 이미지" : "Wallet Image"} /></div>
                <p className="title">{language === 'ko' ? '지갑 연결' : 'Connect Wallet'}</p>
                <p className="text">
                  {language === 'ko' 
                    ? <>지갑 연결을 클릭하여 MetaMask, WalletConnect, 또는 CoinBase를 연결합니다.</>
                    : <>Click on Connect Wallet to connect MetaMask, WalletConnect, or CoinBase.</>}
                </p>
              </div>
            </li>
            <li>
              <div className="main__text__wr">
                <p className="tit">INTEREST PAYOUT IN USDT</p>
                <div className="img__wr"><img src={require('./assets/how_icon02.png')} alt={language === 'ko' ? "돼지저금통" : "Piggy Bank"} /></div>
                <p className="title">{language === 'ko' ? '이자 수익 지급' : 'Interest Payment'}</p>
                <p className="text">
                  {language === 'ko' 
                    ? <>스테이킹을 통해 발생한 이자 수익은 USDT로 계산되며, 벅스가 전송된 지갑 주소로 자동 지급됩니다.</>
                    : <>Interest income generated through staking is calculated in USDT and automatically paid to the wallet address where BGSC was sent.</>}
                </p>
              </div>
            </li>
            <li>
              <div className="main__text__wr">
                <p className="tit">APPLY FOR STAKING</p>
                <div className="img__wr"><img src={require('./assets/how_icon03.png')} alt="" /></div>
                <p className="title">{language === 'ko' ? '스테이킹 신청' : 'Apply for Staking'}</p>
                <p className="text">
                  {language === 'ko' 
                    ? <>스테이킹을 원하는 경우, 수령 지갑 주소에 벅스를 입금한 뒤 해당 지갑에서 스테이킹을 신청하세요.</>
                    : <>If you want to stake, deposit BGSC to the receiving wallet address and apply for staking from that wallet.</>}
                </p>
              </div>
            </li>
            <li>
              <div className="main__text__wr">
                <p className="tit">ADVANCE REWARD PAYMENT</p>
                <div className="img__wr"><img src={require('./assets/how_icon04.png')} alt="" /></div>
                <p className="title">{language === 'ko' ? '선지급 보상' : 'Advance Reward Payment'}</p>
                <p className="text">
                  {language === 'ko' 
                    ? <>스테이킹 신청이 마감되면, 사전에 정해진 보상 금액의 USDT가 수령 지갑 주소로 선지급됩니다.</>
                    : <>When staking applications are closed, the predetermined reward amount in USDT will be paid in advance to the receiving wallet address.</>}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

// 스테이킹 옵션 컴포넌트
const StakingOptions = ({ language, openStakingModal }: { language: string, openStakingModal: (planId: number) => void }) => {
  return (
    <div className="main__contents__wr main__contents__wr03" id="main__contents__wr03">
      <div className="inner__box">
        <div className="logo_wr"><img src={require('./assets/bgsc_logo.svg').default} alt="" /></div>
        <div className="main__title__wr">
          <p className="title">STAKING SEASON 1</p>
        </div>

        <div className="main__contents__box main__contents__box03" id="main__contents__box03">
          <ul className="text__list">
            <li>
              <div className="text__wr">
                <div className="top__text__wr">
                  <p className="text">{language === 'ko' ? '14일 스테이킹' : '14 Days Staking'}</p>
                  <p className="tx__box tx__box01">{language === 'ko' ? '연이율 : ' : 'APY: '}<b>60%</b></p>
                </div>

                <div className="tx__list">
                  <div className="list">
                    <p className="tx01">{language === 'ko' ? '이자풀' : 'Interest Pool'}</p>
                    <p className="tx02">100,000 USDT</p>
                  </div>
                  <div className="list">
                    <p className="tx01">{language === 'ko' ? '예치한도' : 'Deposit Limit'}</p>
                    <p className="tx02">4,345,238 USDT</p>
                  </div>
                </div>

                <div className="bar_line img__wr"><img src={require('./assets/bgsc_bar01.png')} alt="" /></div>
                <button onClick={() => openStakingModal(1)} className="main__btn bgsc__btn">
                  {language === 'ko' ? '스테이킹' : 'Staking'}
                </button>
              </div>
            </li>
            <li>
              <div className="text__wr">
                <div className="top__text__wr">
                  <p className="text">{language === 'ko' ? '30일 스테이킹' : '30 Days Staking'}</p>
                  <p className="tx__box tx__box02">{language === 'ko' ? '연이율 : ' : 'APY: '}<b>90%</b></p>
                </div>

                <div className="tx__list">
                  <div className="list">
                    <p className="tx01">{language === 'ko' ? '이자풀' : 'Interest Pool'}</p>
                    <p className="tx02">100,000 USDT</p>
                  </div>
                  <div className="list">
                    <p className="tx01">{language === 'ko' ? '예치한도' : 'Deposit Limit'}</p>
                    <p className="tx02">1,351,852 USDT</p>
                  </div>
                </div>

                <div className="bar_line img__wr"><img src={require('./assets/bgsc_bar01.png')} alt="" /></div>
                <button onClick={() => openStakingModal(2)} className="main__btn bgsc__btn">
                  {language === 'ko' ? '스테이킹' : 'Staking'}
                </button>
              </div>
            </li>
            <li>
              <div className="text__wr">
                <div className="top__text__wr">
                  <p className="text">{language === 'ko' ? '60일 스테이킹' : '60 Days Staking'}</p>
                  <p className="tx__box tx__box03">{language === 'ko' ? '연이율 : ' : 'APY: '}<b>200%</b></p>
                </div>

                <div className="tx__list">
                  <div className="list">
                    <p className="tx01">{language === 'ko' ? '이자풀' : 'Interest Pool'}</p>
                    <p className="tx02">400,000 USDT</p>
                  </div>
                  <div className="list">
                    <p className="tx01">{language === 'ko' ? '예치한도' : 'Deposit Limit'}</p>
                    <p className="tx02">1,216,667 USDT</p>
                  </div>
                </div>

                <div className="bar_line img__wr"><img src={require('./assets/bgsc_bar02.png')} alt="" /></div>
                <button onClick={() => openStakingModal(3)} className="main__btn bgsc__btn bgsc__btn02">
                  {language === 'ko' ? '스테이킹' : 'Staking'}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

// 푸터 컴포넌트
const Footer = ({ language }: { language: string }) => {
  return (
    <footer className="footer">
      <div className="inner__box">
        <div className="top__text__list">
          <a href="#">Point</a>
          <span></span>
          <a href="#" className="how__tx">How to Use</a>
          <span></span>
          <a href="#">Point</a>
        </div>

        <ul>
          <li>
            <a href="#">Privacy policy</a>
            <a href="#">Terms of services</a>
          </li>

          <li>
            <p className="copy">Copyright &copy; <b>Bugscoin.</b> All rights reserved.</p>
          </li>
        </ul>
      </div>
    </footer>
  );
};

// 법적 고지 모달 컴포넌트
interface LegalNoticeProps {
  onClose: () => void;
  language: 'ko' | 'en';
}

const LegalNotice = ({ onClose, language }: LegalNoticeProps) => {
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{language === 'ko' ? '법적 고지' : 'Legal Notice'}</h2>
          <button className="close-btn" onClick={onClose}>✕</button>
        </div>
        <div className="modal-content">
          <div className="warning-icon">
            <img src={require('./assets/warning_icon.svg').default} alt="Warning" />
          </div>
          <p>
            {language === 'ko' 
              ? <>본 스테이킹 서비스는 현재 법적 검토 중에 있습니다. 실제 서비스 출시 전에 변경될 수 있으며, 이 페이지는 서비스의 개념과 기능을 보여주기 위한 데모입니다.</>
              : <>This staking service is currently under legal review. It may change before the actual service launch, and this page is a demo to show the concept and functionality of the service.</>}
          </p>
          <button className="confirm-btn" onClick={onClose}>
            {language === 'ko' ? '이해했습니다' : 'I Understand'}
          </button>
        </div>
      </div>
    </div>
  );
};

// 스테이킹 모달 컴포넌트
interface StakingModalProps {
  onClose: () => void;
  language: 'ko' | 'en';
  planId: number;
}

const StakingModal = ({ onClose, language, planId }: StakingModalProps) => {
  const [amount, setAmount] = useState('');
  const [showLegalNotice, setShowLegalNotice] = useState(false);
  const maxAmount = 1000; // 최대 스테이킹 금액 설정
  const usdtRate = 0.15; // BGSC당 USDT 가치
  
  // 현재 날짜로부터 계획에 따른 해제일 계산
  const getUnlockDate = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date.toLocaleDateString(language === 'ko' ? 'ko-KR' : 'en-US', { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    });
  };

  const getStakingPlanDetails = () => {
    switch (planId) {
      case 1:
        return {
          days: 14,
          apy: 30,
          title: language === 'ko' ? '14일 스테이킹' : '14 Days Staking'
        };
      case 2:
        return {
          days: 30,
          apy: 90,
          title: language === 'ko' ? '30일 스테이킹' : '30 Days Staking'
        };
      case 3:
        return {
          days: 60,
          apy: 200,
          title: language === 'ko' ? '60일 스테이킹' : '60 Days Staking'
        };
      default:
        return {
          days: 0,
          apy: 0,
          title: ''
        };
    }
  };
  
  const plan = getStakingPlanDetails();
  const inputAmount = Number(amount) || 0;
  const usdtValue = (inputAmount * usdtRate).toFixed(2);
  const reward = (inputAmount * plan.apy / 100 * plan.days / 365).toFixed(2);
  const unlockDate = getUnlockDate(plan.days);
  
  const handleConfirm = () => {
    setShowLegalNotice(true);
  };
  
  const handleLegalNoticeClose = () => {
    setShowLegalNotice(false);
    onClose(); // 법적 고지 모달 닫을 때 스테이킹 모달도 함께 닫기
  };

  return (
    <>
      <div className="modal-overlay" onClick={onClose}>
        <div className="staking-modal" onClick={(e) => e.stopPropagation()}>
          <div className="staking-modal-header">
            <h2>{plan.title}</h2>
            <button className="close-btn" onClick={onClose}>✕</button>
          </div>
          <div className="staking-modal-content">
            <div className="staking-input-container">
              <label className="staking-input-label">
                {language === 'ko' ? '스테이킹 금액 (BGSC)' : 'Staking Amount (BGSC)'}
              </label>
              <div className="staking-input-wrapper">
                <input 
                  type="number" 
                  className="staking-input"
                  value={amount} 
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="0.00"
                  min="0"
                  max={maxAmount}
                />
                <button className="max-btn" onClick={() => setAmount(maxAmount.toString())}>
                  {language === 'ko' ? '최대' : 'Max'}
                </button>
              </div>
              <div className="staking-max-amount">
                {language === 'ko' ? '최대 스테이킹 금액' : 'Max Staking Amount'}: {maxAmount} BGSC
              </div>
            </div>

            <div className="staking-details">
              <div className="staking-detail">
                <div className="staking-detail-label">{language === 'ko' ? 'USDT 가치' : 'USDT Value'}</div>
                <div className="staking-detail-value">{usdtValue} USDT</div>
              </div>
              <div className="staking-detail">
                <div className="staking-detail-label">{language === 'ko' ? '이자율' : 'APY'}</div>
                <div className="staking-detail-value">{plan.apy}%</div>
              </div>
              <div className="staking-detail">
                <div className="staking-detail-label">{language === 'ko' ? '받을 보상' : 'Reward'}</div>
                <div className="staking-detail-value">{reward} USDT</div>
              </div>
              <div className="staking-detail">
                <div className="staking-detail-label">{language === 'ko' ? '잠금 해제일' : 'Unlock Date'}</div>
                <div className="staking-detail-value">{inputAmount > 0 ? unlockDate : '-'}</div>
              </div>
            </div>

            <div className="staking-notice">
              <p>{language === 'ko' ? '연결된 지갑 주소를 꼼꼼히 확인해 주세요.' : 'Please carefully check your connected wallet address.'}</p>
              <p>{language === 'ko' ? '신청 마감 시, 연결된 지갑 주소로 USDT가 전송됩니다.' : 'When the application closes, USDT will be sent to the connected wallet address.'}</p>
              <p>{language === 'ko' ? '잠금 해제 시, 연결된 지갑 주소로 벅스가 자동 반환됩니다.' : 'When unlocked, BGSC will be automatically returned to the connected wallet address.'}</p>
            </div>

            <div className="staking-buttons">
              <button className="cancel-btn" onClick={onClose}>
                {language === 'ko' ? '취 소' : 'Cancel'}
              </button>
              <button 
                className="confirm-btn" 
                onClick={handleConfirm}
              >
                {language === 'ko' ? '확 인' : 'Confirm'}
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {showLegalNotice && <LegalNotice language={language} onClose={handleLegalNoticeClose} />}
    </>
  );
};

function App() {
  const [language, setLanguage] = useState<'ko' | 'en'>('ko');
  const [showLegalModal, setShowLegalModal] = useState(false);  
  const [showStakingModal, setShowStakingModal] = useState(false);
  const [currentStakingPlan, setCurrentStakingPlan] = useState<number>(1); 
  const [legalModalCallback, setLegalModalCallback] = useState<(() => void) | undefined>(undefined);
  
  // 모달 관련 함수들
  const closeLegalModal = () => {
    setShowLegalModal(false);
    // 콜백이 있으면 실행
    if (legalModalCallback) {
      legalModalCallback();
      setLegalModalCallback(undefined);
    }
  };
  
  const openLegalReviewModal = (callback?: () => void) => {
    // 약간의 지연을 줘서 확실히 지갑 모달 위에 표시되도록 함
    setTimeout(() => {
      setLegalModalCallback(callback);
      setShowLegalModal(true);
    }, 100);
  };
  
  const openStakingModal = (planId: number) => {
    setCurrentStakingPlan(planId);
    setShowStakingModal(true);
  };
  const closeStakingModal = () => setShowStakingModal(false);
  
  // 언어 변경 함수
  const toggleLanguage = () => {
    setLanguage(language === 'ko' ? 'en' : 'ko');
  };

  useEffect(() => {
    // CSS에서 루트 요소에 데이터 속성 설정
    document.documentElement.setAttribute('data-lang', language);
  }, [language]); // language를 의존성으로 추가

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <div className="main__wr">
            <RocketAnimation />
            <div className="frame__wr">
              <Header language={language} toggleLanguage={toggleLanguage} openLegalReviewModal={openLegalReviewModal} />
              <MainContents language={language} openLegalReviewModal={openLegalReviewModal} />
              <KeyPoints language={language} />
              <HowToUse language={language} />
              <StakingOptions language={language} openStakingModal={openStakingModal} />
              <Footer language={language} />
              
              {/* 모달 컴포넌트들 */}
              {showLegalModal && <LegalReviewModal onClose={closeLegalModal} language={language} onConfirm={legalModalCallback} />}
              {showStakingModal && <StakingModal onClose={closeStakingModal} language={language} planId={currentStakingPlan} />}
              
              {/* 플로팅 언어 변경 버튼 */}
              <button className="language-toggle-btn" onClick={toggleLanguage}>
                <span className="lang-icon">
                  <i className="fas fa-globe"></i>
                </span>
                {language === 'ko' ? 'EN' : 'KR'}
              </button>
              
              {/* WhiteMode 버튼 */}
              <a href="/whitemode" className="whitemode-btn">
                <span className="mode-icon">
                  <i className="fas fa-sun"></i>
                </span>
                Light
              </a>
            </div>
          </div>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
